<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-info btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#pablo">Authentic.net Account Portal</a>
    </div>

    <template slot="navbar-menu">
      <p-button v-if="loading" type="primary" round disabled="disabled">
        {{ selectedBrand.name }}
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </p-button>
      <drop-down v-if="!loading">
        <p-button slot="title"
                  slot-scope="{isOpen}"
                  type="primary"
                  round
                  block
                  :aria-expanded="isOpen"
                  class="dropdown-toggle">
          {{ selectedBrand.name }}
        </p-button>
        <div class="dropdown-header">Switch Active Brand</div>
        <a class="dropdown-item" v-for="brand in user.account.brands" :key="brand.id" href="javascript:void(0)" @click.prevent="selectBrand(brand)">{{ brand.name }}</a>
      </drop-down>
      <form v-if="false">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>
      <ul v-if="false" class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btn-magnify" href="#pablo">
            <i class="nc-icon nc-layout-11"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <drop-down icon="nc-icon nc-bell-55" tag="li"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown">
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link btn-rotate" href="#pablo">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import UserService from 'src/services/user.service'
  import _ from 'lodash'

  export default {
    components: {
      Navbar,
      NavbarToggleButton
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
        selectedBrand: { },
        loading: false
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      async init () {
        this.setSelected()
      },
      async setSelected () {
        if (_.isEmpty(this.selectedBrand)) {
          if (this.user.options.active_brand_id) {
            this.selectedBrand = this.user.account.brands[_.findIndex(this.user.account.brands, b => { return b.id === this.user.options.active_brand_id })]
          } else {
            this.selectedBrand = this.user.account.brands[0]
          }
        }
      },
      async selectBrand (brand) {
        if (this.selectedBrand.id === brand.id) return false
        this.selectedBrand = brand
        this.loading = true
        this.setUserOptions({ active_brand_id: this.selectedBrand.id })
      },
      async setUserOptions (options) {
        await UserService.updateOptions(options)
        this.me()
        this.loading = false
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
