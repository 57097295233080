<template>
  <div class="user">
    <div class="photo">
      <v-gravatar :email="user.email" :alt="user.name" :size="200" default-img="mm" />
      <!-- <img src="static/img/faces/face-2.jpg" alt="user avatar"/> -->
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{ user.name }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="javascript:void(0)" @click="profile">
                <span class="sidebar-mini-icon">A</span>
                <span class="sidebar-normal">Account</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="logout">
                <span class="sidebar-mini-icon">L</span>
                <span class="sidebar-normal">Logout</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { CollapseTransition } from 'vue2-transitions'
  import { mapGetters, mapActions } from 'vuex'
  import Gravatar from 'vue-gravatar'

  Vue.component('v-gravatar', Gravatar)

  export default {
    components: {
      CollapseTransition
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      })
    },
    data() {
      return {
        isClosed: true
      }
    },
    methods: {
      ...mapActions({
        signOutAction: 'auth/signOut'
      }),
      async logout () {
        this.toggleMenu()
        await this.signOutAction()
        this.$router.replace({ name: 'Login' })
      },
      profile () {
        this.$router.replace({ name: 'Profile' })
      },
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
