import BrandService from 'src/services/brand.service'

export default {
  namespaced: true,

  state: {
    brands: []
  },

  getters: {
    brands (state) {
      return state.brands
    }
  },

  mutations: {
    SET_BRANDS (state, value) {
      state.brands = value
    }
  },

  actions: {
    getBrands ({ commit }) {
      return BrandService.index().then(function (response) {
        commit('SET_BRANDS', response.data)
      }).catch(function () {
        commit('SET_BRANDS', [])
      })
    },

    closeOut ({ commit }) {
      commit('SET_BRANDS', [])
    }
  }
}
