export default [
  {
    name: 'Overview',
    icon: 'nc-icon nc-layout-11',
    path: '/account/overview'
  },
  {
    name: 'My Brands',
    icon: 'nc-icon nc-bold',
    path: '/account/brands'
  },
  {
    name: 'Products',
    icon: 'nc-icon nc-box-2',
    path: '/account/products'
  },
  {
    name: 'Retailers',
    icon: 'nc-icon nc-cart-simple',
    path: '/account/retailers'
  },
  {
    name: 'Label Creator',
    icon: 'nc-icon nc-touch-id',
    path: '/account/label-creator'
  },
  // {
  //   name: 'Batch Scan-In',
  //   icon: 'nc-icon nc-tag-content',
  //   path: '/account/batch-scan-in'
  // },
  {
    name: 'Registrations',
    icon: 'nc-icon nc-badge',
    path: '/account/registrations'
  },
]
