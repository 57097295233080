<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="javascript:void(0);">
              Terms
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              Privacy
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              Support
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Authentic.net
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
