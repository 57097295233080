/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - V2.3.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2019 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import axios from 'axios'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar'
import VTooltip from 'v-tooltip'
import LoadScript from 'vue-plugin-load-script'

// router setup
import routes from './routes/routes'

// vuex modules
import auth from './vuex/auth.vuex'
import brands from './vuex/brands.vuex'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// console.log('process.env', process.env)

axios.defaults.withCredentials = true
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL == null ? 'https://api-staging.authentic.net' : process.env.VUE_APP_API_BASE_URL
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(Vuex)
Vue.use(VTooltip)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(LoadScript)
locale.use(lang)

const store = new Vuex.Store({
  modules: {
    auth,
    brands
  }
})

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  // mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.state.authenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else if (auth.state.user.account.status === 'Pending') {
      next({
        path: '/registration?aid=' + auth.state.user.account_id,
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (auth.state.authenticated) {
      next({
        path: '/admin/overview',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

initProgress(router);

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   render: h => h(App),
//   router
// })

store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
