import Api from 'src/apis/api'

class ResetPasswordService {
  create (token, email, password, password_confirmation) {
    let data = {
      token: token,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    }

    return Api({
      method: 'post',
      url: '/api/reset-password',
      data: data
    })
  }
}

export default new ResetPasswordService()
