import Api from 'src/apis/api'

class UserService {
  login (credentials) {
    return Api.post('/login', credentials, {
      headers: {
        'Accept': 'application/json'
      }
    })
  }

  logout () {
    return Api.post('/logout')
  }

  updateName (name) {
    return Api.post('/api/user/?_method=PUT', {'name': name})
  }

  updateEmail (email) {
    return Api.post('/api/user/?_method=PUT', {'email': email})
  }

  updateOptions (options) {
    return Api.put('/api/user/options', { options: options })
  }
}

export default new UserService()
