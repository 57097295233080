<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="submit">
                <card type="login">
                  <h3 slot="header" class="header text-center">
                    <div style="text-align: center; padding-top: 20px; padding-bottom: 10px;">
                      <a href="https://www.authentic.net"><img src="static/img/authentic_net_lt.png" width="200" alt="Authentic.net"></a>
                    </div>
                    Reset Password
                  </h3>

                  <fg-input v-model="data.password" addon-left-icon="nc-icon nc-key-25"
                            placeholder="Password" type="password"></fg-input>

                  <fg-input v-model="data.password_confirmation" addon-left-icon="nc-icon nc-key-25" placeholder="Confirm Password"
                            type="password"></fg-input>

                  <br>

                  <p-button native-type="submit" slot="footer" type="primary" v-if="!loading" round block class="mb-3">Submit</p-button>
                  <p-button native-type="submit" slot="footer" type="primary" v-if="loading" round block class="mb-3" disabled="disabled">
                    <span class="btn-label">
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    Login
                  </p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/architecture-barge-bay-beach-161764.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import swal from 'sweetalert2'
  import ResetPasswordService from 'src/services/resetPassword.service'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Button.name]: Button
    },
    mixins: [NotifyMixin],
    data() {
      return {
        data: {
          token: '',
          email: '',
          password: '',
          password_confirmation: ''
        },
        loading: false
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      init() {
        if (this.$route.params.token && this.$route.params.email) {
          this.data.token = this.$route.params.token
          this.data.email = this.$route.params.email
        } else if (this.$route.query.token && this.$route.query.email) {
          this.data.token = this.$route.query.token
          this.data.email = this.$route.query.email
        } else {
          this.showNotify('Token or Email missing. Redirecting to login.', 'danger', 'Invalid Password Reset Link')
          this.$router.replace({ name: 'Login' })
        }
      },
      submit() {
        this.loading = true
        ResetPasswordService.create(this.data.token, this.data.email, this.data.password, this.data.password_confirmation)
        .then(() => {
          this.loading = false
          this.showNotify('Password updated. Redirecting to login.', 'success', 'Success')
          this.$router.replace({ name: 'Login' })
        }).catch(err => {
          console.log('err', err.response)
          if (err.response.data.errors) {
            var msg = ''
            let fields = Object.keys(err.response.data.errors)
            for(let i = 0; i < fields.length; i++) {
              for (let j = 0; j < err.response.data.errors[fields[i]].length; j++) {
                if (msg !== '') msg += ' - '
                msg += err.response.data.errors[fields[i]][j]
              }
            }
            this.showNotify(msg, 'danger', 'Error')
            this.loading = false
          } else if(err.response.data.message) {
            this.showNotify(err.response.data.message, 'danger', 'Error')
            this.loading = false
          } else {
            this.showNotify(err.message, 'danger', 'Error')
            this.loading = false
          }
        })
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
