export const NotifyMixin = {
  methods: {
    showNotify (message, type = '', title = '', icon = '',
            verticalAlign = 'bottom', horizontalAlign = 'right') {
      // type: ['', 'info', 'success', 'warning', 'danger']
      var msg = title === '' ? `${message}` : `${title} - ${message}`
      this.$notify(
        {
          message: msg,
          icon: icon,
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: type === '' ? 'info' : type
        })
    }
  }
}
