import Api from 'src/apis/api'

class ForgotPasswordService {
  create (email) {
    let data = {
      email: email
    }

    return Api({
      method: 'post',
      url: '/api/forgot-password',
      data: data
    })
  }
}

export default new ForgotPasswordService()
