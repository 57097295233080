import axios from 'axios'

class BrandService {
  index () {
    return axios.get('/api/account/brands')
  }

  get (id) {
    return axios({
      method: 'get',
      url: '/api/account/brand',
      data: { id: id }
    })
  }

  create (brand) {
    const data = new FormData()
    data.append('name', brand.name)
    data.append('img_temp_file_id', brand.imgTempFileId)
    data.append('redirect_url', brand.redirect_url)
    return axios.post('/api/account/brands', data)
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/account/brands',
      data: { id: id }
    })
  }
}

export default new BrandService()
