import axios from 'axios'

// let baseUrl = process.env.VUE_APP_API_BASE_URL == null ? 'https://api-staging.authentic.net' : process.env.VUE_APP_API_BASE_URL
let baseUrl = process.env.VUE_APP_API_BASE_URL

let Api = axios.create({
  baseURL: baseUrl,
  withCredentials: true
})

export default Api
