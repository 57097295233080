import axios from 'axios'
import UserService from 'src/services/user.service'
import CsrfApi from 'src/apis/csrf.api'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
      await CsrfApi.getCookie()
      await UserService.login(credentials)

      return dispatch('me')
    },

    async signOut ({ dispatch }) {
      await UserService.logout()

      return dispatch('me')
    },

    me ({ commit }) {
      return axios.get('/api/user').then(function (response) {
        var user = response.data
        if (user.options === null) user.options = {}
        else user.options = JSON.parse(user.options)
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', user)
      }).catch(function () {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }
  }
}
