import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
// const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
// const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Authnet Account Pages
const AccountOverview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/AccountOverview.vue')
const Products = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/Products.vue')
const Brands = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/Brands.vue')
const SalesChannels = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/SalesChannels.vue')
const Registrations = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/Registrations.vue')
const Profile = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/Profile.vue')
const RegisterViaCode = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Pages/RegisterViaCode.vue')
const Registration = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Pages/Registration/Registration.vue')
const LabelCreator = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/LabelCreator.vue')
const BatchScanIn = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/BatchScanIn.vue')
const BatchScan = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/BatchScan.vue')
const ChangePlan = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Account/ChangePlan.vue')

const Scan = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Pages/Scan.vue')
const Authenticate = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Pages/Authenticate.vue')
const RegistrationThankyou = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Pages/RegistrationThankyou.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

import ResetPassword from 'src/components/Dashboard/Views/Pages/ResetPassword.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let resetPasswordPage = {
  path: '/reset-password',
  name: 'Reset Password',
  component: ResetPassword
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let registerViaCodePage = {
  path: '/register-with-invite',
  name: 'Register With Invite',
  component: RegisterViaCode
}

let RegistrationPage = {
  path: '/registration',
  name: 'Registration',
  component: Registration
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

let scanPage = {
  path: '/scan',
  name: 'Scan',
  component: Scan
}

let authenticatePage = {
  path: '/authenticate',
  name: 'Authenticate',
  component: Authenticate
}

let registrationThankyou = {
  path: '/thank-you',
  name: 'Thank You',
  component: RegistrationThankyou
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  resetPasswordPage,
  registerPage,
  registerViaCodePage,
  RegistrationPage,
  lockPage,
  scanPage,
  authenticatePage,
  registrationThankyou,
  {
    // should just get rid of this demo chunk, but loading for casey so we'll
    // redirect for now
    path: '/admin',
    redirect: { name: "Account Overview" },
    children: [
      {
        path: 'overview',
        name: 'Overview',
        redirect: { name: "Account Overview" },
        meta: { authOnly: true }
      },
      {
        path: 'widgets',
        name: 'Widgets',
        redirect: { name: "Account Overview" }
      }
    ]
  },
  {
    path: '/account',
    component: DashboardLayout,
    redirect: '/account/overview',
    children: [
      {
        path: 'overview',
        name: 'Account Overview',
        component: AccountOverview,
        meta: { authOnly: true }
      },
      {
        path: 'products',
        name: 'Products',
        component: Products,
        meta: { authOnly: true }
      },
      {
        path: 'brands',
        name: 'Brands',
        component: Brands,
        meta: { authOnly: true }
      },
      {
        path: 'retailers',
        name: 'Retailers',
        component: SalesChannels,
        meta: { authOnly: true }
      },
      {
        path: 'registrations',
        name: 'Registrations',
        component: Registrations,
        meta: { authOnly: true }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { authOnly: true }
      },
      {
        path: 'label-creator',
        name: 'Label Creator',
        component: LabelCreator,
        meta: { authOnly: true }
      },
      {
        path: 'batch-scan-in',
        name: 'Batch Scan In',
        component: BatchScanIn,
        meta: { authOnly: true }
      },
      {
        path: 'change-plan',
        name: 'Change Plan',
        component: ChangePlan,
        meta: { authOnly: true }
      }
    ]
  },
  {
    path: '/account/batch-scan',
    name: 'Batch Scan',
    component: BatchScan,
    meta: { authOnly: true }
  },
  {path: '*', component: NotFound}
];

export default routes
